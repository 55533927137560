import { concatRespectingCase } from "../utils/stringUtils.js";

import { PivotGroupByField, PivotValueField } from "./pivotTypes";

export function pivotGroupByFieldName({
  field,
  nameOverride,
  truncateTo,
}: PivotGroupByField): string {
  if (nameOverride) {
    return nameOverride;
  } else if (truncateTo) {
    return concatRespectingCase(field, `_${truncateTo}`);
  } else {
    return field;
  }
}

export function pivotValueFieldName({
  aggregation,
  field,
  nameOverride,
}: PivotValueField): string {
  if (nameOverride) {
    return nameOverride;
  } else {
    return concatRespectingCase(field, `_${aggregation}`);
  }
}
