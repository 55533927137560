import { keyBy } from "lodash";

import { SemanticDatasetName } from "../idTypeBrands.js";
import { notEmpty } from "../notEmpty.js";
import { SemanticDatasetNode, SemanticDatasetTree } from "../semanticLayer.js";
import {
  typedObjectEntries,
  typedObjectFromEntries,
  typedObjectKeys,
} from "../utils/typedObjects.js";

export type SemanticModelGraph = Record<
  SemanticDatasetName,
  Set<SemanticDatasetName>
>;

export type SemanticDatasetStub = {
  name: string;
  properties: {
    joins?: { target: string; name?: string | null }[] | null;
  };
};

/**
 *   This contains the mapping of target table name to any named joins. Forevery
 *   named join we create a node in the graph that corresponds to the named
 *   join, which is just a reference/pointer to the target table.
 */
type NamedJoins = Record<string, string[]>;

export const getNamedJoins = (
  datasets: readonly SemanticDatasetStub[],
): NamedJoins => {
  const namedJoins: NamedJoins = {};

  datasets.forEach((dataset) => {
    dataset.properties.joins?.forEach((join) => {
      if (join.name) {
        (namedJoins[join.target] ??= []).push(join.name);
      }
    });
  });

  return namedJoins;
};
const getChildrenForDataset = (
  dataset: SemanticDatasetStub,
): Set<SemanticDatasetName> => {
  return new Set(
    dataset.properties.joins?.map(
      (join) => (join.name ?? join.target) as SemanticDatasetName,
    ) ?? [],
  );
};

export const getSemanticModelGraph = (
  allDatasets: readonly SemanticDatasetStub[],
): { graph: SemanticModelGraph; namedJoins: NamedJoins } => {
  const namedJoins: NamedJoins = getNamedJoins(allDatasets);
  const graph = typedObjectFromEntries(
    allDatasets.map((dataset) => [
      dataset.name,
      getChildrenForDataset(dataset),
    ]),
  );

  typedObjectKeys(graph).forEach((dataset) => {
    if (namedJoins[dataset]) {
      namedJoins[dataset].forEach((namedJoin) => {
        graph[namedJoin as SemanticDatasetName] = graph[dataset]!;
      });
    }
  });
  return { graph, namedJoins };
};

const getPathsPerNode = (
  startingDataset: SemanticDatasetName,
  graph: SemanticModelGraph,
): Record<SemanticDatasetName, number> => {
  const pathCount: Record<SemanticDatasetName, number> = {};

  const pathNodes = new Set<SemanticDatasetName>();

  function dfs(node: SemanticDatasetName): void {
    if (pathNodes.has(node)) {
      return;
    }
    pathNodes.add(node);

    pathCount[node] = (pathCount[node] ??= 0) + 1;

    if (graph[node] == null) {
      throw Error(
        `No children found for ${node}. The join graph may be invalid.`,
      );
    }
    for (const child of graph[node]) {
      dfs(child);
    }
    pathNodes.delete(node);
  }

  dfs(startingDataset);
  return pathCount;
};

/**
 *
 * @param startingDataset The "root" dataset to start from
 * @param allDatasets All datasets in the semantic project
 *
 * This takes all datasets, which can be modeled as a directed graph, and turns
 * the graph into a dataset tree, where the top level nodes in the tree
 * represent datasets with clear join paths from the starting dataset, and child
 * nodes of the tree represent datasets with multiple join paths.
 *
 * See semanticGraph.test.ts for more examples, but in this example graph
 *  A: ["B", "C"]
 *  B: ["C", "D"]
 *  C: ["D"]
 *  D: []
 *
 * There are multiple ways to get from starting dataset A to datasets C,D, so
 * those datasets must be nested. There's only one way to get to datset B, so
 * that can be a top level node.
 *
 * A: [C]
 * B: [C, D]
 * C: [D]
 * D: []
 *
 *
 **/
export const getSemanticDatasetTree = <T extends SemanticDatasetStub>(
  startingDataset: SemanticDatasetName,
  allDatasets: readonly T[],
): {
  tree: SemanticDatasetTree;
  rootNodes: SemanticDatasetNode[];
  /**
   * The datasets referenced in the semantic dataset tree. If
   * there are any named joins in the tree this will contain the name of the
   * corresponding target dataset.
   */
  datasetsInTree: T[];
} => {
  const { graph, namedJoins } = getSemanticModelGraph(allDatasets);

  // Flip the mapping so that the key is the join name (which is used in the
  // tree), and the value is the corresponding target table name
  const joinNameToTargetName = typedObjectFromEntries(
    typedObjectEntries(namedJoins).flatMap(([target, joinNames]) => {
      return joinNames.map((joinName) => [joinName, target]);
    }),
  );

  const datasetByName = keyBy(allDatasets, "name");

  const { rootNodes, tree } = getSemanticDatasetTreeFromGraph(
    startingDataset,
    graph,
  );

  return {
    tree,
    rootNodes,
    datasetsInTree: typedObjectKeys(tree)
      .map(
        (name) =>
          datasetByName[name] ??
          datasetByName[joinNameToTargetName[name] ?? ""],
      )
      .filter(notEmpty),
  };
};

/**
 * Exposed for testing purposes only
 */
export const getSemanticDatasetTreeFromGraph = (
  startingDataset: SemanticDatasetName,
  graph: SemanticModelGraph,
): {
  tree: SemanticDatasetTree;
  rootNodes: SemanticDatasetNode[];
} => {
  const pathCount: Record<SemanticDatasetName, number> = getPathsPerNode(
    startingDataset,
    graph,
  );

  const tree: SemanticDatasetTree = {};
  const treeWithSet: SemanticModelGraph = {};
  const nodesInPath = new Set<SemanticDatasetName>();
  const rootNodes: SemanticDatasetNode[] = [];

  // If there are V nodes and E edges in the graph, then in the worst case this
  // can run in O((V + E)^2) time, since for downstream children of nested nodes
  // we need to re-traverse the graph.
  function addNodeToTree(
    graphNode: SemanticDatasetName,
    parentGraphNode: SemanticDatasetName | null,
    // This represents the TREE parent node of the parent graph node. We use
    // this when we have nested nodes in the tree so that we can determine
    // whether or not we need to continue nesting or can flatten out the nodes.
    parentTreeNode: SemanticDatasetName | null,
  ): void {
    if (nodesInPath.has(graphNode)) {
      return;
    }
    nodesInPath.add(graphNode);

    let newParentTreeNode = graphNode;
    const nodePathCount = pathCount[graphNode]!;

    if (nodePathCount === 0) {
      // if we are at a node then it must be connected to the root/starting node
      throw Error("There should be at least one path to a node");
    }
    if (nodePathCount > 1) {
      if (parentGraphNode == null || parentTreeNode == null) {
        // if we are at the root node, there should only ever be one path to the node to it
        throw Error("There should not be multiple paths to a root node");
      }

      // Determine how many paths from the parent tree node to the current node
      // to determine if we need to nest further. Note - this may result in us
      // having to traverse the graph again for every node, but I can't think of
      // a better way to do this
      const paths = getPathsPerNode(parentTreeNode, graph);

      if (paths[graphNode]! > 1) {
        if (!treeWithSet[parentGraphNode]!.has(graphNode)) {
          treeWithSet[parentGraphNode]!.add(graphNode);
          tree[parentGraphNode]!.push({
            name: graphNode,
            pathToNode: [graphNode],
          });
          newParentTreeNode = parentGraphNode;
        }
      } else {
        treeWithSet[parentTreeNode]!.add(graphNode);
        tree[parentTreeNode]!.push({
          name: graphNode,
          pathToNode:
            parentGraphNode !== parentTreeNode
              ? [parentGraphNode, graphNode]
              : [graphNode],
        });
        newParentTreeNode = parentTreeNode;
      }
    } else {
      rootNodes.push({
        name: graphNode,
        // we don't want to include the starting dataset as a node
        pathToNode: [...nodesInPath].filter((n) => n !== startingDataset),
      });
    }
    treeWithSet[graphNode] ??= new Set();
    tree[graphNode] ??= [];
    for (const child of graph[graphNode]!) {
      addNodeToTree(child, graphNode, newParentTreeNode);
    }
    nodesInPath.delete(graphNode);
  }

  addNodeToTree(startingDataset, null, null);

  return { tree, rootNodes };
};

/**
 * Given a graph of datasets, and a link between two datasets to be removed,
 * returns a map of other downstream links to remove as well.
 *
 * As an example, say you had the following dataset graph:
 *
 *              -> G
 *            /
 * A -> B -> C -> D -> E
 *  \            /
 *   -> F ------
 *
 * If we said we wanted to remove the A -> B link, then the result would be:
 *
 * {
 *   A: {B},
 *   B: {C},
 *   C: {D, G}
 * }
 *
 * And the new graph would look like this:
 *
 * A -> F -> D -> E
 *
 * The logic below depends on their being no cycles in the graph, otherwise it could fail.
 * Its main intention right now is for user-added joins,
 * but I defined the logic here as we eventually want to support user-added joins
 * on existing semantic projects.
 *
 * We test this logic in `editExploreSpecReducer.test.ts`
 *
 */
export const getJoinsToDelete = (
  allDatasets: readonly SemanticDatasetStub[],
  join: readonly [SemanticDatasetName, SemanticDatasetName],
): Record<SemanticDatasetName, Set<SemanticDatasetName>> => {
  const { graph } = getSemanticModelGraph(allDatasets);

  const invertedGraph: SemanticModelGraph = {};
  for (const [start, ends] of typedObjectEntries(graph)) {
    for (const end of ends) {
      invertedGraph[end] ??= new Set();
      invertedGraph[end].add(start);
    }
  }

  const joinsToRemove: Record<SemanticDatasetName, Set<SemanticDatasetName>> = {
    [join[0]]: new Set([join[1]]),
  };
  const tempJoinsToCheck = [join];

  while (tempJoinsToCheck.length > 0) {
    const [baseTableName, targetTableName] = tempJoinsToCheck.pop()!;

    // Remove join edge from the graph
    graph[baseTableName]?.delete(targetTableName);
    invertedGraph[targetTableName]?.delete(baseTableName);

    // If the target had no other incoming edges, remove it and repeat for its children.
    // If it _did_ have incoming edges, then we want to keep it around
    const hasOtherIncomingEdges =
      (invertedGraph[targetTableName]?.size ?? 0) > 0;
    if (!hasOtherIncomingEdges && graph[targetTableName] != null) {
      for (const child of graph[targetTableName]) {
        tempJoinsToCheck.push([targetTableName, child]);

        joinsToRemove[targetTableName] ??= new Set();
        joinsToRemove[targetTableName].add(child);
      }
    }
  }

  return joinsToRemove;
};
