export function setIntersection<T>(a: Iterable<T>, b: Iterable<T>): Set<T> {
  const overlap = new Set(a);
  const compare: ReadonlySet<T> = b instanceof Set ? b : new Set(b);
  for (const curr of a) {
    if (!compare.has(curr)) {
      overlap.delete(curr);
    }
  }
  return overlap;
}

export function setDifference<T>(a: Iterable<T>, b: Iterable<T>): Set<T> {
  const returnVal = new Set(a);
  for (const curr of b) {
    returnVal.delete(curr);
  }
  return returnVal;
}

/**
 * Given `subset`, a set of elements all of which are part of `array`,
 * efficienty returns a new array of `subset`'s elements sorted in the same order as they appear in `array`
 */
export function sortSubsetInSameOrder<T>(
  subset: Iterable<T>,
  array: readonly T[],
): T[] {
  const subsetSet: ReadonlySet<T> =
    subset instanceof Set ? subset : new Set(subset);
  const resultArray: T[] = [];
  for (const ele of array) {
    if (subsetSet.has(ele)) {
      resultArray.push(ele);

      // no need to keep iterating over `array` if we've already found all of our elements' locations
      if (resultArray.length === subsetSet.size) {
        break;
      }
    }
  }

  return resultArray;
}

export function setEquals<T>(a: Set<T>, b: Set<T>): boolean {
  if (a.size !== b.size) return false;
  for (const el of a) {
    if (!b.has(el)) {
      return false;
    }
  }
  return true;
}
